<template>
  <b-card
    class="allotoutboundinbounditem-edit-wrapper"
  >
    <!-- form -->
    <b-form id="allotoutboundinbounditemForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="调拨入库单ID allot_outbound_inbound.inbound_id"
            label-for="inbound_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inbound_id"
              size="sm"
              v-model="allotoutboundinbounditem.inbound_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="调拨入库单编号 allot_outbound_inbound.inbound_no"
            label-for="inbound_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inbound_no"
              size="sm"
              v-model="allotoutboundinbounditem.inbound_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="调拨单ID allot_outbound.outbound_id"
            label-for="allot_outbound_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="allot_outbound_id"
              size="sm"
              v-model="allotoutboundinbounditem.allot_outbound_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="调拨单编号 allot_outbound.outbound_no"
            label-for="allot_outbound_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="allot_outbound_no"
              size="sm"
              v-model="allotoutboundinbounditem.allot_outbound_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="调拨单明细ID allot_outbound_item.outbounditem_id"
            label-for="allot_outbounditem_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="allot_outbounditem_id"
              size="sm"
              v-model="allotoutboundinbounditem.allot_outbounditem_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="allotoutboundinbounditem.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="allotoutboundinbounditem.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="分类ID"
            label-for="category_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="category_id"
              size="sm"
              v-model="allotoutboundinbounditem.category_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="分类名称"
            label-for="category_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="category_name"
              size="sm"
              v-model="allotoutboundinbounditem.category_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label=""
            label-for="stocklock_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stocklock_id"
              size="sm"
              v-model="allotoutboundinbounditem.stocklock_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="ID"
            label-for="stock_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="stock_id"
              size="sm"
              v-model="allotoutboundinbounditem.stock_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="产品批次"
            label-for="batch_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="batch_no"
              size="sm"
              v-model="allotoutboundinbounditem.batch_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="生产日期"
            label-for="production_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="production_date"
              size="sm"
              v-model="allotoutboundinbounditem.production_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="有效日期"
            label-for="expiry_date"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="expiry_date"
              size="sm"
              v-model="allotoutboundinbounditem.expiry_date"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存状态 instock_status"
            label-for="warestatus"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warestatus"
              size="sm"
              v-model="allotoutboundinbounditem.warestatus"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="送货数量"
            label-for="qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="qty"
              size="sm"
              v-model="allotoutboundinbounditem.qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库数量_良品"
            label-for="receiveqty_good"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="receiveqty_good"
              size="sm"
              v-model="allotoutboundinbounditem.receiveqty_good"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库数量_不良品"
            label-for="receiveqty_bad"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="receiveqty_bad"
              size="sm"
              v-model="allotoutboundinbounditem.receiveqty_bad"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="启用"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="allotoutboundinbounditem.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="create_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="create_time"
              size="sm"
              v-model="allotoutboundinbounditem.create_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加人ID"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="allotoutboundinbounditem.creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新时间"
            label-for="update_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="update_time"
              size="sm"
              v-model="allotoutboundinbounditem.update_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新人ID"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="allotoutboundinbounditem.updator"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import allotoutboundinbounditemStore from './allotoutboundinbounditemStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      allotoutboundinbounditem: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('allotoutboundinbounditem')) store.registerModule('allotoutboundinbounditem', allotoutboundinbounditemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('allotoutboundinbounditem')) store.unregisterModule('allotoutboundinbounditem')
    })

    const edit = function() {
      store.dispatch('allotoutboundinbounditem/edit', {id: this.id}).then(res => {
        this.allotoutboundinbounditem = res.data.data
      })
    }

    const view = function() {
      store.dispatch('allotoutboundinbounditem/view', {id: this.id}).then(res => {
        this.allotoutboundinbounditem = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('allotoutboundinbounditem/save', this.allotoutboundinbounditem).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>